import { useContext } from 'react';
import { ThemeContext } from './ThemeProvider';

export const useTheme = () => {
  const theme = useContext(ThemeContext);

  if (theme === null) {
    throw new Error('Using out of ThemeProvider');
  }

  return theme;
};
