import { StylishBoxResolutionProps, StylishBoxResultCSSObj } from '../types';

function getDisplayStyles(styles: StylishBoxResolutionProps): StylishBoxResultCSSObj {
  const result: StylishBoxResultCSSObj = {};

  if (styles.display) {
    result.display = styles.display;
  }

  if (styles.overflow) {
    result.overflow = styles.overflow;
  }

  if (styles.overflowY) {
    result.overflowY = styles.overflowY;
  }

  if (styles.overflowX) {
    result.overflowX = styles.overflowX;
  }

  if (styles.textOverflow) {
    result.textOverflow = styles.textOverflow;
  }


  if (styles.visibility) {
    result.visibility = styles.visibility;
  }

  if (styles.whiteSpace) {
    result.whiteSpace = styles.whiteSpace;
  }

  if (styles.transition) {
    result.transition = styles.transition;
  }

  return result;
}

function getFlexStyles(styles: StylishBoxResolutionProps): StylishBoxResultCSSObj {
  const result: StylishBoxResultCSSObj = {};

  if (styles.flexDirection) {
    result.flexDirection = styles.flexDirection;
  }

  if (styles.flexWrap) {
    result.flexWrap = styles.flexWrap;
  }

  if (styles.justifyContent) {
    result.justifyContent = styles.justifyContent;
  }

  if (styles.alignItems) {
    result.alignItems = styles.alignItems;
  }

  if (styles.alignContent) {
    result.alignContent = styles.alignContent;
  }

  if (styles.order) {
    result.order = styles.order;
  }

  if (styles.flex) {
    result.flex = styles.flex;
  }

  if (styles.flexGrow) {
    result.flexGrow = styles.flexGrow;
  }

  if (styles.flexShrink) {
    result.flexShrink = styles.flexShrink;
  }

  if (styles.flexBasis) {
    result.flexBasis = styles.flexBasis;
  }

  if (styles.alignSelf) {
    result.alignSelf = styles.alignSelf;
  }

  if (styles.gap) {
    result.gap = styles.gap;
  }

  return result;
}

function getPositionStyles(styles: StylishBoxResolutionProps): StylishBoxResultCSSObj {
  const result: StylishBoxResultCSSObj = {};

  if (styles.position) {
    result.position = styles.position;
  }

  if (styles.top) {
    result.top = styles.top;
  }

  if (styles.right) {
    result.right = styles.right;
  }

  if (styles.bottom) {
    result.bottom = styles.bottom;
  }

  if (styles.left) {
    result.left = styles.left;
  }

  if (styles.zIndex) {
    result.zIndex = styles.zIndex;
  }

  return result;
}

function getBoxShadowStyles(
  styles: StylishBoxResolutionProps,
): StylishBoxResultCSSObj {
  const result: StylishBoxResultCSSObj = {};
  const { boxShadow } = styles;

  if (boxShadow) {
    result.boxShadow = boxShadow;
  }

  return result;
}

function getSizeStyles(styles: StylishBoxResolutionProps): StylishBoxResultCSSObj {
  const result: StylishBoxResultCSSObj = {};

  if (styles.width) {
    result.width = styles.width;
  }

  if (styles.minWidth) {
    result.minWidth = styles.minWidth;
  }

  if (styles.maxWidth) {
    result.maxWidth = styles.maxWidth;
  }

  if (styles.height) {
    result.height = styles.height;
  }

  if (styles.minHeight) {
    result.minHeight = styles.minHeight;
  }

  if (styles.maxHeight) {
    result.maxHeight = styles.maxHeight;
  }

  return result;
}

function getSpaceStyles(styles: StylishBoxResolutionProps): StylishBoxResultCSSObj {
  const result: StylishBoxResultCSSObj = {};

  if (styles.p) {
    result.padding = styles.p;
  }

  if (styles.py) {
    result.paddingTop = styles.py;
    result.paddingBottom = styles.py;
  }

  if (styles.px) {
    result.paddingRight = styles.px;
    result.paddingLeft = styles.px;
  }

  if (styles.pt) {
    result.paddingTop = styles.pt;
  }

  if (styles.pr) {
    result.paddingRight = styles.pr;
  }

  if (styles.pb) {
    result.paddingBottom = styles.pb;
  }

  if (styles.pl) {
    result.paddingLeft = styles.pl;
  }

  if (styles.m) {
    result.margin = styles.m;
  }

  if (styles.my) {
    result.marginTop = styles.my;
    result.marginBottom = styles.my;
  }

  if (styles.mx) {
    result.marginRight = styles.mx;
    result.marginLeft = styles.mx;
  }

  if (styles.mt) {
    result.marginTop = styles.mt;
  }

  if (styles.mr) {
    result.marginRight = styles.mr;
  }

  if (styles.mb) {
    result.marginBottom = styles.mb;
  }

  if (styles.ml) {
    result.marginLeft = styles.ml;
  }

  return result;
}

function getColorStyles(styles: StylishBoxResolutionProps): StylishBoxResultCSSObj {
  const result: StylishBoxResultCSSObj = {};

  if (styles.color) {
    result.color = styles.color;
  }

  if (styles.bgColor) {
    result.backgroundColor = styles.bgColor;
  }

  return result;
}

function getTypographyStyles(
  styles: StylishBoxResolutionProps,
): StylishBoxResultCSSObj {
  const result: StylishBoxResultCSSObj = {};

  if (styles.fontFamily) {
    result.fontFamily = styles.fontFamily;
  }

  if (styles.fontSize) {
    result.fontSize = styles.fontSize;
  }

  if (styles.fontStyle) {
    result.fontStyle = styles.fontStyle;
  }

  if (styles.fontWeight) {
    result.fontWeight = styles.fontWeight;
  }

  if (styles.letterSpacing) {
    result.letterSpacing = styles.letterSpacing;
  }

  if (styles.lineHeight) {
    result.lineHeight = styles.lineHeight;
  }

  if (styles.wordBreak) {
    result.wordBreak = styles.wordBreak;
  }

  if (styles.verticalAlign) {
    result.verticalAlign = styles.verticalAlign;
  }

  if (styles.textAlign) {
    result.textAlign = styles.textAlign;
  }

  if (styles.textTransform) {
    result.textTransform = styles.textTransform;
  }

  if (styles.textDecoration) {
    result.textDecoration = styles.textDecoration;
  }

  return result;
}

function getBorderStyles(styles: StylishBoxResolutionProps): StylishBoxResultCSSObj {
  const result: StylishBoxResultCSSObj = {};

  if (styles.border) {
    result.border = styles.border;
  }

  if (styles.borderTop) {
    result.borderTop = styles.borderTop;
  }

  if (styles.borderRight) {
    result.borderRight = styles.borderRight;
  }

  if (styles.borderBottom) {
    result.borderBottom = styles.borderBottom;
  }

  if (styles.borderLeft) {
    result.borderLeft = styles.borderLeft;
  }

  if (styles.borderColor) {
    result.borderColor = styles.borderColor;
  }

  if (styles.borderRadius) {
    result.borderRadius = styles.borderRadius;
  }

  return result;
}

const getCursorStyles = (styles: StylishBoxResolutionProps): StylishBoxResultCSSObj => {
  const result: StylishBoxResultCSSObj = {};

  if (styles.cursor) {
    result.cursor = styles.cursor;
  }

  return result;
};

const getOpacityStyles = (styles: StylishBoxResolutionProps): StylishBoxResultCSSObj => {
  const result: StylishBoxResultCSSObj = {};

  if (styles.opacity) {
    result.opacity = styles.opacity;
  }

  return result;
};

const getBackgroundStyles = (styles: StylishBoxResolutionProps): StylishBoxResultCSSObj => {
  const result: StylishBoxResultCSSObj = {};

  if (styles.backgroundColor) {
    result.backgroundColor = styles.backgroundColor;
  }

  return result;
};

const getTransformStyles = (styles: StylishBoxResolutionProps): StylishBoxResultCSSObj => {
  const result: StylishBoxResultCSSObj = {};

  if (styles.transform) {
    result.transform = styles.transform;
  }

  return result;
};

const getListStyles = (styles: StylishBoxResolutionProps): StylishBoxResultCSSObj => {
  const result: StylishBoxResultCSSObj = {};

  if (styles.listStyle) {
    result.listStyle = styles.listStyle;
  }

  return result;
};

const getResolutionStyles = (
  styles: StylishBoxResolutionProps,
): StylishBoxResultCSSObj => ({
  ...getTransformStyles(styles),
  ...getOpacityStyles(styles),
  ...getBackgroundStyles(styles),
  ...getDisplayStyles(styles),
  ...getFlexStyles(styles),
  ...getPositionStyles(styles),
  ...getBoxShadowStyles(styles),
  ...getSizeStyles(styles),
  ...getSpaceStyles(styles),
  ...getColorStyles(styles),
  ...getTypographyStyles(styles),
  ...getBorderStyles(styles),
  ...getCursorStyles(styles),
  ...getListStyles(styles),
});

export default getResolutionStyles;
