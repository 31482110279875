import { baseTheme } from './skins/base';
import { ThemeContext, ThemeProviderProps } from './ThemeProvider';

interface ThemeProviderMockProps {
  children: ThemeProviderProps['children'];
}

const ThemeProviderMock = (props: ThemeProviderMockProps) => {
  const { children } = props;

  return (
    <ThemeContext.Provider value={baseTheme}>
      { children }
    </ThemeContext.Provider>
  );
};

export default ThemeProviderMock;
