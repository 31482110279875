import { StylesByResolutions, StylishBoxStyles } from '../types';
import { divideStylesByResolutions } from './divideStylesByResolutions';

const divideStylesByResolutionsAndStates = (
  sbs: StylishBoxStyles | undefined,
): StylesByResolutions => {
  if (!sbs) {
    return {};
  }

  const { hover: hoverStyles, ...defaultStyles } = sbs;

  const {
    general,
    desktop,
    desktopTablet,
    tablet,
    tabletPhone,
    phone,
  } = divideStylesByResolutions(defaultStyles);

  if (hoverStyles) {
    const {
      general: generalHover,
      desktop: desktopHover,
      desktopTablet: desktopTabletHover,
      tablet: tabletHover,
      tabletPhone: tabletPhoneHover,
      phone: phoneHover,
    } = divideStylesByResolutions(hoverStyles);

    return {
      general,
      generalHover,
      desktop,
      desktopHover,
      desktopTablet,
      desktopTabletHover,
      tablet,
      tabletHover,
      tabletPhone,
      tabletPhoneHover,
      phone,
      phoneHover,
    };
  }

  return {
    general,
    desktop,
    desktopTablet,
    tablet,
    tabletPhone,
    phone,
  };
};

export default divideStylesByResolutionsAndStates;
