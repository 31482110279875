import { ThemeBaseVariablesCSS } from './types';

export const createCssVariablesStylesString = (baseVariablesCSS: ThemeBaseVariablesCSS) => {
  let stylesString = ':root {';

  for (const variable in baseVariablesCSS) {
    const value = baseVariablesCSS[variable];

    stylesString += `${variable}: ${value};`;
  }

  stylesString += '}';

  return stylesString;
};
