import { ThemeSkin, ThemeSkinConfig } from '../types';
import zakaz from './zakaz';
import dark from './dark';

const skins: {[key in ThemeSkin]: ThemeSkinConfig } = {
  zakaz,
  dark,
};

export default skins;
