import { ThemeSkinConfig } from '../types';
import { baseVariablesCSS, baseTheme } from './base';

const zakazSkinConfig: ThemeSkinConfig = {
  css: {
    ...baseVariablesCSS,
  },
  theme: {
    ...baseTheme,
  },
};

export default zakazSkinConfig;
