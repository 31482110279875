import { createContext, ReactNode } from 'react';
import { createCssVariablesStylesString } from './helpers';
import skins from './skins';
import { Theme, ThemeSkin } from './types';

export const ThemeContext = createContext<null | Theme>(null);

export interface ThemeProviderProps {
  children: ReactNode;
  skin: ThemeSkin;
}

const ThemeProvider = ({
  children,
  skin = 'zakaz',
}: ThemeProviderProps) => {
  const skinConfig = skins[skin];
  const css = createCssVariablesStylesString(skinConfig.css);

  return (
    <ThemeContext.Provider value={skinConfig.theme}>
      <style dangerouslySetInnerHTML={{ __html: css }} />
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
