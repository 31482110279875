import { Theme } from '../../theme/types';
import { StylishBoxColorProps, StylishBoxStyles, UnwrapResponsiveProps } from '../types';

const getColorValue = (
  colorProps: UnwrapResponsiveProps<StylishBoxColorProps> | undefined,
  themeColors: Theme['base']['color'],
  defaultColorValue: string,
) => {
  if (!colorProps) {
    return undefined;
  }

  if (colorProps === 'default') {
    return defaultColorValue;
  }

  if (colorProps === 'inherit') {
    return 'inherit';
  }

  return themeColors[colorProps];
};

const transformColorPropsToSBS = (
  colorProps: StylishBoxColorProps | undefined,
  themeColors: Theme['base']['color'],
  defaultColorValue: string,
): StylishBoxStyles['color'] => {
  if (colorProps) {
    if (typeof colorProps === 'string') {
      return getColorValue(colorProps, themeColors, defaultColorValue);
    }

    return {
      general: getColorValue(colorProps.general, themeColors, defaultColorValue) || defaultColorValue,
      desktop: getColorValue(colorProps.desktop, themeColors, defaultColorValue),
      desktopTablet: getColorValue(colorProps.desktopTablet, themeColors, defaultColorValue),
      tablet: getColorValue(colorProps.tablet, themeColors, defaultColorValue),
      tabletPhone: getColorValue(colorProps.tabletPhone, themeColors, defaultColorValue),
      phone: getColorValue(colorProps.phone, themeColors, defaultColorValue),
    };
  }

  return defaultColorValue;
};

export default transformColorPropsToSBS;
