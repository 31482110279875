import React from 'react';
import StylishBox from '../StylishBox/StylishBox';
import { useTheme } from '../theme';
import { getElement, getSBS } from './helpers';
import { TypographyAllowedElements, TypographyProps, Variant, VariantElementMap } from './types';


const Typography = <
  V extends Variant = 'text2',
  E extends TypographyAllowedElements = VariantElementMap[V],
>({
  variant = 'text2' as V,
  element,
  color: colorProps,
  align = 'left',
  wrapped = false,
  sbs,
  children,
  elementProps,
  dataMarker,
  dataTestId,
  className,
}: TypographyProps<V, E>) => {

  const theme = useTheme();
  const resultElement = getElement(element, variant);
  const resultSBS = getSBS({
    variant,
    wrapped,
    colorProps,
    colorDefault: theme.typography.color,
    themeColors: theme.base.color,
    textAlign: align,
    sbsProps: sbs,
    fontFamily: theme.typography.fontFamily,
    fontFamilyAccented: theme.typography.fontFamilyAccented,
  });

  return (
    <StylishBox
      element={resultElement}
      sbs={resultSBS}
      elementProps={elementProps}
      dataMarker={dataMarker}
      dataTestId={dataTestId}
      className={className}
    >
      {children}
    </StylishBox>
  );
};

export default Typography;
