import { StylesByResolutions, StylishBoxStyles } from '../types';

export function divideStylesByResolutions(styles: Omit<StylishBoxStyles, 'hover'>) {
  return Object.keys(styles).reduce<StylesByResolutions>((acc, key) => {
    const value = styles[key];

    if (typeof value === 'object') {
      if (value.general) {
        if(!acc.general) {
          acc.general = {};
        }

        acc.general[key] = value.general;
      }

      if (value.desktop) {
        if (!acc.desktop) {
          acc.desktop = {};
        }

        acc.desktop[key] = value.desktop;
      }

      if (value.desktopTablet) {
        if (!acc.desktopTablet) {
          acc.desktopTablet = {};
        }

        acc.desktopTablet[key] = value.desktopTablet;
      }

      if (value.tablet) {
        if (!acc.tablet) {
          acc.tablet = {};
        }

        acc.tablet[key] = value.tablet;
      }

      if (value.tabletPhone) {
        if (!acc.tabletPhone) {
          acc.tabletPhone = {};
        }

        acc.tabletPhone[key] = value.tabletPhone;
      }

      if (value.phone) {
        if (!acc.phone) {
          acc.phone = {};
        }

        acc.phone[key] = value.phone;
      }
    } else {
      if (!acc.general) {
        acc.general = {};
      }

      acc.general[key] = value;
    }

    return acc;
  }, {});
}
