import { ThemeSkinConfig } from '../types';
import { baseVariablesCSS, baseTheme } from './base';

const darkSkinConfig: ThemeSkinConfig = {
  css: {
    ...baseVariablesCSS,
    '--base-color-secondary': '#000',
    '--base-border-radius-2': '20px',
  },
  theme: {
    ...baseTheme,
    button: {
      ...baseTheme.button,
      //todo: add example of overwriting button properties
    },
  },
};

export default darkSkinConfig;
